import moment from 'moment';
import { USER_DATE_FORMAT } from '../../utils/constants';
import { WaybillCargoAdditionalServiceCode } from './types';

export const DATE_TIME_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ'

export const CONTROL_360_PRESETS = {
  PICKUP_YESTERDAY: 'pickupyesterday'
}

const waybillStatus = [
  {
    id: 'new',
    title: 'Заказ создан',
    color: 'gray',
  },
  {
    id: 'registered',
    title: 'Заказ создан',
    color: 'gray',
  },
  {
    id: 'pickup_waiting',
    title: ({ pickup_date }: any) => `Заказ создан${pickup_date ? `. Ожидаемая дата сбора ${moment(pickup_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'blue',
  },
  {
    id: 'courier_search',
    title: ({ pickup_date }: any) => `Ищем курьера${pickup_date ? `. Ожидаемая дата сбора ${moment(pickup_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'blue',
  },
  {
    id: 'courier_on_way',
    title: ({ pickup_date }: any) => `Курьер едет к отправителю${pickup_date ? `. Ожидаемая дата сбора ${moment(pickup_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'blue',
  },
  {
    id: 'received_from_sender',
    title: ({ delivery_date }: any) => `Принят у отправителя${delivery_date ? `. Ожидаемая дата доставки ${moment(delivery_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  {
    id: 'ready_to_send',
    title: ({ delivery_date }: any) => `Готово к отправке${delivery_date ? `. Ожидаемая дата доставки ${moment(delivery_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  {
    id: 'sent_to_receiver_office',
    title: ({ delivery_date }: any) => `Отправлено в офис получателя${delivery_date ? `. Ожидаемая дата доставки ${moment(delivery_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  {
    id: 'met_in_receiver_office',
    title: ({ delivery_date }: any) => `Встретили в офисе получателя${delivery_date ? `. Ожидаемая дата доставки ${moment(delivery_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  {
    id: 'received_in_delivery_office',
    title: ({ delivery_date }: any) => `Принято в офисе доставки${delivery_date ? `. Ожидаемая дата доставки ${moment(delivery_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  {
    id: 'courier_delivery',
    title: ({ delivery_date }: any) => `Доставляет курьер${delivery_date ? `. Ожидаемая дата доставки ${moment(delivery_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  {
    id: 'receiver_waiting_in_office',
    title: ({ delivery_date }: any) => `Ожидаем получателя в офисе${delivery_date ? `. Ожидаемая дата доставки ${moment(delivery_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  {
    id: 'delivery_waiting',
    title: ({ delivery_date }: any) => `Груз в пути${delivery_date ? `. Ожидаемая дата доставки ${moment(delivery_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  {
    id: 'delivered',
    title: ({ delivery_date }: any) => `Заказ выполнен${delivery_date ? ` ${moment(delivery_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'green',
  },
  {
    id: 'canceled',
    title: ({ cancel_date }: any) => `Заказ отменен${cancel_date ? ` ${moment(cancel_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'red',
  },
  {
    id: 'lost',
    title: ({ cancel_date }: any) => `Заказ утерян${cancel_date ? ` ${moment(cancel_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'red',
  },
  {
    id: 'scrapped',
    title: ({ cancel_date }: any) => `Заказ утилизирован${cancel_date ? ` ${moment(cancel_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'red',
  },
  {
    id: 'canceled_negotiation',
    title: ({ canceled_negotiation_date }: any) => `Заказ в согласовании на отмену${canceled_negotiation_date ? ` ${moment(canceled_negotiation_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'red',
  },
  {
    id: 'problem',
    status: ({ problem_date }: any) => `Проблема в процессе доставки${problem_date ? ` ${moment(problem_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
];

export const waybillAdditionalServices = [
  {
    key: WaybillCargoAdditionalServiceCode.INSURANCE,
    title: 'Страховка',
  },
  {
    key: WaybillCargoAdditionalServiceCode.CARGO_LOADING,
    title: 'Погрузочные работы при приеме груза',
  },
  {
    key: WaybillCargoAdditionalServiceCode.CARGO_UNLOADING,
    title: 'Разгрузочные работы при доставке груза',
  },
  {
    key: 'RETURN',
    title: 'Возврат',
  },
  {
    key: WaybillCargoAdditionalServiceCode.BY_HAND,
    title: 'Вручение лично в руки'
  },
  {
    key: 'PAY_BY_RECEIVER',
    title: 'Оплата получателем',
  },
  {
    key: 'GIDROBORT',
    title: 'Гидроборт'
  },
  {
    key: 'TEMPERATURE_CONDITION',
    title: 'Температурный режим от -20 до +5'
  },
  {
    key: 'LOAD_UNLOAD',
    title: 'Погрузка-Разгрузка'
  },
  {
    key: 'PRIORITY_CONTROL',
    title: 'Приоритетный контроль'
  },
  {
    key: 'PICKUP_IN_60_MIN',
    title: 'Забрать за 60 мин'
  },
];

export const waybillDeliveryStatusChoices = [
  {
    key: 'received_from_sender',
    title: 'Принят у отправителя',
  },
  {
    key: 'ready_to_send',
    title: 'Готово к отправке',
  },
  {
    key: 'sent_to_receiver_office',
    title: 'Отправлено в офис получателя',
  },
  {
    key: 'met_in_receiver_office',
    title: 'Встретили в офисе получателя',
  },
  {
    key: 'received_in_delivery_office',
    title: 'Принято в офисе доставки',
  },
  {
    key: 'courier_delivery',
    title: 'Доставляет курьер',
  },
  {
    key: 'receiver_waiting_in_office',
    title: 'Ожидаем получателя в офисе',
  },
  {
    key: 'delivery_waiting',
    title: 'Груз в пути',
  }
]

export const waybillPickupWaitingStatusChoices = [
  {
    key: 'courier_search',
    title: 'Ищем курьера',
  },
  {
    key: 'courier_on_way',
    title: 'Курьер едет к отправителю',
  },
]

export default waybillStatus;
